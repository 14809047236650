import styled from "styled-components"

export default styled.div`
  background: white;
  border-radius: 10px;
  border: 2px solid #d8dbe1;
  box-shadow: 0 4px #d8dbe1;
  padding: 16px;
  display: block;
`
