import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const Text = styled.span`
  margin: 0;
  font-size: ${props => {
    switch (props.size) {
      case "sm":
        return "1.2rem"
      case "md":
        return "1.6rem"
      case "lg":
        return "1.8rem"
      case "xl":
        return "2.4rem"
      case "xxl":
        return "3.0rem"
      case "h1":
        return "6.4rem"
      case "h2":
        return "4rem"
      default:
        return "3.2rem"
    }
  }};

  font-weight: ${props => props.weight};

  line-height: ${props => props.lineHeight ?? 1};

  margin-top: ${props => props.theme.verticalSpacing(props.mt ?? 0)};
  margin-bottom: ${props => props.theme.verticalSpacing(props.mb ?? 0)};
  margin-left: ${props => props.theme.verticalSpacing(props.ml ?? 0)};
  margin-right: ${props => props.theme.verticalSpacing(props.mr ?? 0)};
`

export default Text

Text.propTypes = {
  as: PropTypes.elementType,
  size: PropTypes.oneOf([
    "sm",
    "md",
    "lg",
    "xl",
    "xxl",
    "h1",
    "h2",
    "h3",
    "h4",
  ]),
  weight: PropTypes.oneOf([300, 400, 500, 600, 700]),
  lineHeight: PropTypes.string,
}
