import React from "react"
import styled, { css } from "styled-components"
import Button from "../lib/button"
import Img from "gatsby-image"
import card from "../lib/card"
import Text from "../lib/text"

export default function NewsLink({ title, link, date, image }) {
  return (
    <NewsLinkWrapper
      as="a"
      href={link}
      rel="noopener noreferrer"
      target="_blank"
    >
      {image ? <Img fluid={image.childImageSharp.fluid} /> : null}
      <TextCol>
        <NewsDate size="md" as="p">
          {new Date(date).toLocaleDateString("en-us", {
            year: "numeric",
            month: "long",
          })}
        </NewsDate>
        <NewsTitle>{title}</NewsTitle>
        <Button
          as="p"
          css={{
            display: "inline-block",
            textDecoration: "none",
            margin: 0,
            marginTop: 24,
            alignSelf: "flex-end",
          }}
        >
          Read Article
        </Button>
      </TextCol>
    </NewsLinkWrapper>
  )
}

const NewsLinkWrapper = styled(card)`
  text-decoration: none;
  color: inherit;

  > *:nth-child(2) {
    margin-top: 20px;
  }

  @media (min-width: 500px) {
    display: flex;
    flex-direction: row;
    height: 100%;

    > * {
      flex-basis: 50%;
      flex-grow: 1;

      &:nth-child(2) {
        margin-top: 0;
        margin-left: 20px;
      }
    }
  }

  > .gatsby-image-wrapper {
    border-radius: 6px;
  }
`

const NewsDate = styled.p`
  margin: 0;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 400;
  opacity: 0.7;
`

const NewsTitle = styled.h3`
  margin: 0;
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: auto;
  margin-top: 16px;
`

const TextCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
