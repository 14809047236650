import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/archive/layout"
import SEO from "../components/archive/seo"
import Grid from "../components/archive/lib/grid"
import Jumbotron from "../components/archive/jumbotron"
import styled from "styled-components"
import NewsLink from "../components/archive/news/NewsLink"
import { HR } from "./careers"

const News = ({ data }) => {
  return (
    <Layout initialHeaderLight={true}>
      <SEO title="News" />
      <Grid>
        <Grid.Row>
          <Grid.Col>
            <Jumbotron.Title css={{ marginTop: "6rem" }}>
              Pumpjack in the Media
            </Jumbotron.Title>
            <HR />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col>
            <NewsList>
              {data.allMarkdownRemark.edges.map(item => (
                <li key={item.node.id}>
                  <NewsLink {...item.node.frontmatter} />
                </li>
              ))}
            </NewsList>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Layout>
  )
}

export default News

export const query = graphql`
  query NewsQuery {
    allMarkdownRemark(
      filter: { fields: { type: { eq: "news" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            link
            date
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          id
        }
      }
    }
  }
`

const NewsList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 24px;
`
